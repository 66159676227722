<template>
  <b-overlay :show="formShow" rounded="true" :fixed="true" no-fade style="height:100vh">
    <b-card>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" class="bg-primary text-white align-middle" style="font-size: 1.2em;">
                School Growth Profile
              </th>
              <th scope="col" class="bg-primary text-white d-flex justify-content-end align-middle">
                <b-button type="submit" @click="showArchiveRecords()" variant="warning" class="mr-1">
                  {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
                </b-button>

                <b-button type="submit" @click.prevent="viewAsChart = !viewAsChart" variant="warning" class="float-right animate__animated animate__fadeIn">
                  {{ viewAsChart ? 'View as Table' : 'View as Chart' }}
                </b-button>
              </th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" xl="3" lg="4" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedEvent" :options="eventTypes" :reduce="(val) => val" :filterable="true" placeholder="Search by event" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="3" lg="4" md="12" sm="12">
          <b-form-group>
            <v-select id="event" v-model="selectedSchool" :options="schools" :reduce="(val) => val" :filterable="true" placeholder="Search by school" @option:selected="filterBySchool" label="name" :clearable="false" input-id="event" />
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="3" lg="4" md="12" sm="12">
          <b-form-group>
            <b-input-group>
              <flat-pickr :disabled="!resultData.event.groups" placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="{ mode: 'range' }" />
              <b-input-group-append>
                <b-button @click="clearDate()" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-auto" cols="3" xl="3" md="12" sm="12">
          <b-form-group>
            <b-button @click="downloadPdf()" v-show="$Can('produce_a_report')" class="mr-2" block variant="warning" :disabled="!resultData.event.groups.length > 0">
              Generate Report
            </b-button>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="overallAverage > 0">
          <h5 class="text-start">
            The overall average of the selected survey:
            <b>{{ overallAverage }}</b>
          </h5>
          <hr />
        </b-col>
        <b-overlay :show="tableShow" rounded="true" :fixed="true" no-fade class=" mt-2" style="height:100vh" v-if="resultData.event.groups.length > 0">
          <b-tabs vertical pills nav-wrapper-class="nav-vertical" nav-class="nav-left" class="tab-class left-align">
            <b-tab :title="group.groupName.length > 30 ? group.groupName.slice(0, 30) + '...' : group.groupName" v-for="group in resultData.event.groups" :key="group.id" @click="changeTableGroupName(group)">
              <b-col cols="12" xl="12" md="12" sm="12" class="bodyRow" style="padding: 0.5rem 0;" v-if="filteredQuestions.length > 0 && viewAsChart == false">
                <p style="padding-left: 1rem;">
                  <i> {{ filteredQuestions.length > 0 ? group.groupName : '' }}</i>
                </p>

                <b-table-simple fixed striped caption-top style="height: 75vh;" :outlined="true" :responsive="true" :bordered="true" class="animate__animated animate__fadeIn">
                  <b-thead>
                    <b-tr>
                      <b-th style="width:40%">Questions</b-th>
                      <b-th class="text-center" style="width:15%">item score</b-th>
                      <b-th class="text-center" style="width:15%">domain score</b-th>
                      <b-th class="text-center" style="width:30%">Summary Explanation</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(question, index) in filteredQuestions" :key="index">
                      <b-th class="text-left" style="font-weight: normal;">{{ question.title }}</b-th>
                      <b-th class="text-center" :style="question.itemScore < 2.5 ? 'background-color:#f9cfcf' : 'background-color:#D9FFF7'">{{ question.itemScore > 0 ? question.itemScore : 'Not Observed' }}</b-th>
                      <b-th :rowspan="filteredQuestions.length" class="text-center text-white" style="background-color:#1AAE8E" v-if="question.summaryExplanation">{{ question.domainScore }} </b-th>
                      <b-th :rowspan="filteredQuestions.length" class="text-center" v-if="question.summaryExplanation"><p style="font-weight: normal;" v-html="question.summaryExplanation"></p></b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" sm="12" style="padding: 0.5rem 1rem;" v-else-if="filteredQuestions.length > 0 && viewAsChart == true" class="bodyRow animate__animated animate__fadeIn col-auto">
                <GrowthProfileBarChart :width="1000" :height="400" :data="chartData" :refreshChart="refreshChart" :options="chartDataOptions" />
              </b-col>
              <b-col cols="12" xl="12" lg="12" md="12" sm="12" v-else>
                <div class="text-center">
                  <h3>
                    There are no results for this group
                  </h3>
                </div>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-overlay>
        <b-col cols="12" v-else>
          <h3 class="text-center">
            There are no results for this record
          </h3>
        </b-col>
      </b-row>
    </b-card>

    <template #overlay v-if="download">
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
        <p id="cancel-label"><feather-icon icon="DownloadIcon" /> Report creating please wait...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import _ from 'lodash';
import { t } from '@/@core/libs/i18n/utils';
import { backgroundColor } from 'echarts/lib/theme/dark';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import GrowthProfileBarChart from './charts-components/GrowthProfileBarChart.vue';
import { $themeColors } from '@themeConfig';
import 'animate.css';
import moment from 'moment';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    GrowthProfileBarChart,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    return {
      resultData: {
        event: {
          groups: [],
        },
      },
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      baseURL: store.state.app.baseURL,
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      download: false,
      formShow: false,
      tableShow: false,
      schools: [],
      selectedSchool: null,
      groups: [],
      allGroupsData: [],
      filteredQuestions: [],
      pdfFilteredQuestions: [],
      viewAsChart: false,
      refreshChart: false,
      selectedEvent: null,
      ans: null,
      summaryList: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [],
      overallAverage: 0,
      archive: 'N',
      fields: [
        {
          key: 'groupName',
          label: 'group Name',
          thStyle: { width: '40%' },
        },
        {
          key: 'itemScore',
          label: 'item Score',
          class: 'text-center',
          variant: 'info',
          thStyle: { width: '10%' },
        },
        {
          key: 'domainScore',
          label: 'domain Score',
          class: 'text-center',
          variant: 'primary',
          thStyle: { width: '10%' },
        },
        {
          key: 'summaryExplanation',
          label: 'summary Explanation',
          thStyle: { width: '40%' },
        },
      ],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#84D0FF',
            borderColor: 'transparent',
          },
        ],
      },
      chartDataOptions: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        lineAt: 0, // Total Avarage line is here
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: '#6e6b7b',
                fontSize: 10,
                crossAlign: 'center',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                stepSize: 1,
                min: 0,
                max: 5,
                fontColor: '#6e6b7b',
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getSurveyResults();
  },

  created() {},

  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.selectedEvents();
          }
        }
      },
    },
  },

  methods: {
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';

        this.selectedEvents();
      } else {
        this.archive = 'Y';

        this.selectedEvents();
      }
    },

    changeTableGroupName(group) {
      this.fields[0].label = group.groupName.length > 50 ? group.groupName.slice(0, 50) + '...' : group.groupName;
      this.filteredQuestions = _.filter(this.resultData.questionAnswers, {
        groupId: group.id,
      });

      this.filteredQuestions.forEach((element) => {
        this.resultData.event.groups.forEach((group) => {
          if (typeof group.questions == 'object') {
            group.questions = _.values(group.questions);
          }
          group.questions.forEach((question) => {
            if (element.questionId == question.id) {
              element.title = question.title;
            }
          });
        });
      });

      let count = 0;
      if (this.filteredQuestions.length > 0) {
        let domainScoreCount = 0;
        this.filteredQuestions.forEach((question) => {
          this.resultData.questionAndAnswers.forEach((answer) => {
            if (question.questionId === answer.questionId) {
              question.itemScore = answer.commentAnswersAvarage.toFixed(2);
              // if(typeof question.itemScore == 'string') {
              //   question.itemScore = Number(question.itemScore)
              // }

              if (answer.commentAnswersAvarage != 0) {
                this.filteredQuestions[0].domainScore = 0;
                domainScoreCount = domainScoreCount + 1;

                this.filteredQuestions[0].domainScore = ((count += answer.commentAnswersAvarage) / domainScoreCount).toFixed(2);
              }
            }
          });
        });

        if (!this.filteredQuestions[0].domainScore) {
          this.filteredQuestions[0].domainScore = 0;
        }
        this.filteredQuestions = _.uniqBy(this.filteredQuestions, function(e) {
          return e.questionId;
        });

        this.filteredQuestions[0].summaryExplanation = this.summaryFind(this.filteredQuestions[0].domainScore);
      }

      // set chart data
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];

      this.filteredQuestions.forEach((element) => {
        this.chartData.labels.push(element.title);
        if (element.domainScore != 0) {
          element.itemScore = parseFloat(element.itemScore);
          this.chartData.datasets[0].data.push(element.itemScore.toFixed(2));
        }
      });
      if (this.filteredQuestions[0].domainScore && this.filteredQuestions[0].domainScore != 0) {
        this.chartDataOptions.lineAt = this.filteredQuestions[0].domainScore;
      }

      this.refreshChart = !this.refreshChart;

      this.formShow = false;
    },

    pdfAllGroupFiltre(group) {
      this.pdfFilteredQuestions = _.filter(this.resultData.questionAnswers, {
        groupId: group.id,
      });
      this.pdfFilteredQuestions.forEach((element) => {
        this.resultData.event.groups.forEach((group) => {
          group.questions.forEach((question) => {
            if (element.questionId == question.id) {
              element.title = question.title;
              element.groupName = group.groupName;
            }
          });
        });
      });
      let count = 0;
      if (this.pdfFilteredQuestions.length > 0) {
        let domainScoreCount = 0;
        this.pdfFilteredQuestions.forEach((question) => {
          this.resultData.questionAndAnswers.forEach((answer) => {
            if (question.questionId === answer.questionId) {
              question.itemScore = answer.commentAnswersAvarage.toFixed(2);
              this.pdfFilteredQuestions[0].domainScore = 0;

              if (answer.commentAnswersAvarage != 0 && answer.commentAnswersAvarage != 'Not Observed') {
                domainScoreCount = domainScoreCount + 1;
                this.pdfFilteredQuestions[0].domainScore = ((count += answer.commentAnswersAvarage) / domainScoreCount).toFixed(2);
              }
            }
          });
        });

        this.pdfFilteredQuestions = _.uniqBy(this.pdfFilteredQuestions, function(e) {
          return e.questionId;
        });

        this.pdfFilteredQuestions[0].summaryExplanation = this.summaryFind(this.pdfFilteredQuestions[0].domainScore);
      }

      return this.pdfFilteredQuestions;
    },

    downloadPdf() {
      this.formShow = true;
      this.download = true;
      let pdfData = [];
      let info = {
        surveyName: this.selectedEvent.title,
        overallAverage: this.overallAverage,
        date: moment(new Date()).format('MM-DD-YYYY'),
      };
      this.resultData.event.groups.forEach((element) => {
        pdfData.push(this.pdfAllGroupFiltre(element));
      });

      if (pdfData.length > 0) {
        pdfData = _.reject(pdfData, _.isEmpty);
      }

      store
        .dispatch('settings/cpatPdf', {
          data: pdfData,
          info: info,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Growth Report.pdf');
            document.body.appendChild(link);
            link.click();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Report is created!',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.download = false;
            this.formShow = false;
          } else {
            this.formShow = false;
            this.download = false;
          }
        })
        .catch((error) => {
          this.formShow = false;
          this.download = false;
        });
    },

    summaryFind(val) {
      if (val == undefined) {
        val = 0;
      }
      if (this.summaryList.title[1].range > val) {
        return this.summaryList.title[0].title.replaceAll('#domainScore#', "<strong style='color:red'>" + val + '</strong>');
      } else if (this.summaryList.title[2].range > val) {
        return this.summaryList.title[1].title.replaceAll('#domainScore#', "<strong style='color:red'>" + val + '</strong>');
      } else if (this.summaryList.title[3].range > val) {
        return this.summaryList.title[2].title.replaceAll('#domainScore#', "<strong style='color:red'>" + val + '</strong>');
      } else if (this.summaryList.title[4].range > val) {
        return this.summaryList.title[3].title.replaceAll('#domainScore#', "<strong style='color:red'>" + val + '</strong>');
      } else {
        return this.summaryList.title[4].title.replaceAll('#domainScore#', "<strong style='color:red'>" + val + '</strong>');
      }
    },
    getLastEvent() {
      store
        .dispatch('settings/getLastEventItem', [])
        .then((res) => {
          if (res.data) {
            this.selectedEvent = res.data;
            this.selectedEvents(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.selectedEvents();
      }
    },
    filterBySchool() {
      this.selectedEvents(undefined);
    },
    getSurveyResults() {
      this.formShow = true;

      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
      };

      store
        .dispatch('settings/getAllSurveyResults', filter)
        .then((res) => {
          if(res.data.length > 0){

            this.summaryList = res.data[0].summary;
          this.totalRows = res.data.length;
          this.getLastEvent();
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });

          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });

          }

          this.formShow = false;
         
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
    selectedEvents(event) {
      this.tableShow = true;
      if (event != undefined) {
        this.selectedSchool = null;
      }

      let filter = {
        event: event == undefined ? this.selectedEvent : event,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        school: this.selectedSchool && this.selectedSchool !== 'All School' ? this.selectedSchool : null,
        archive: this.archive,
      };
      store
        .dispatch('settings/getSurveysWithId', filter)
        .then((res) => {
          if (res.data.message != 'Data not found') {
            this.resultData = res.data.data;
            this.overallAverage = res.data.data.overallAverage;
            //set chart colour
            this.chartData.datasets[0].backgroundColor = res.data.data.chartColour;

            let totalaa = 0;

            this.schools = [];
            this.schools.push('All School');
            res.data.data.schools.forEach((s) => {
              this.schools.push(s.schoolName);
            });

            this.resultData.questionAndAnswers = _(res.data.data.questionAnswers)
              .groupBy('questionId')
              .map((question, id, index) => ({
                questionId: Number(id),
                commentAnswersTotal: _.sumBy(question, function(o) {
                  if (o.commentAnswer != 'Not Observed' && o.status == 'A') {
                    totalaa = totalaa + 1;
                    return o.commentAnswer;
                  } else if (o.status == 'B') {
                    return 0;
                  } else {
                    return 0;
                  }
                }),

                total: totalaa,

                commentAnswersAvarage:
                  _.sumBy(question, function(o) {
                    if (o.commentAnswer != 'Not Observed' && o.status == 'A') {
                      return o.commentAnswer;
                    } else if (o.status == 'B') {
                      return 0;
                    } else {
                      return 0;
                    }
                  }) / (totalaa > 0 ? totalaa : 1),

                aaaa: _.sumBy(question, function(o) {
                  totalaa = 0;
                }),
              }))
              .value();

            this.viewAsChart = false;
            this.changeTableGroupName(this.resultData.event.groups[0]);
          } else {
            this.resultData = {
              event: {
                groups: [],
              },
            };

            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Warning',
            //     text: '📋 No Survey Found!',
            //     icon: 'AlertOctagonIcon',
            //     variant: 'warning',
            //   },
            // });
          }
          this.tableShow = false;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.left-align .nav-tabs .nav-link {
  justify-content: left !important;
}

.nav-pills .nav-link {
  margin-bottom: 7px;
  white-space: nowrap;
}
</style>
